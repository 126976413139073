
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [
      { field: "CreatedTime" },
      { field: "UserName" },
      { field: "ActionName" },
      { field: "UserName" },
      { field: "EntityTypeName" },
      { field: "EntityName" }
    ];

    const gridOptions: GridOptions = {
      title: "操作紀錄",
      mode: "inline",
      canCreate: false,
      canDelete: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "操作紀錄",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "操作紀錄",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Warehouse.Name",
          title: "倉庫",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.Number",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.Name",
          title: "產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "SaftyQuantity",
          title: "安全庫存",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "left",
          formatter: ({ cellValue }) =>
            cellValue ? CloudFun.utils.formatMoney(cellValue) : "",
          editRender: {
            name: "$input",
            immediate: true,
            props: { type: "number", min: 0 }
          }
        },
        {
          field: "Quantity",
          title: "數量",
          // showHeaderOverflow: true,
          // showOverflow: true,
          // align: "left",
          // resizable: false,
          // formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          slots: { default: "quantity" }
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("inventory/query", params)
          : undefined,
        queryAll: model ? () => model.dispatch("inventory/query") : undefined,
        save: model
          ? params => model.dispatch("inventory/save", params)
          : undefined
      }
    };

    return {
      grid,
      gridOptions
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    }
  }
});
